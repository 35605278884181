<template>
<div>
    <label
      v-if="label"
      class="pr-4 inline-block mb-1 text-uppercase font-bold"
      :class="[
        requiredLabel ? 'required' : '',
        italic ? 'text-italic' : '',
        opacityClass,
        labelClass
      ]"

    > {{ label }}</label>
    <div :class="[...parentClasses]" class="flex justify-between align-center rounded border border-solid border-gray-500 border w-full">
        <p class="pl-2 text-base w-75p" style="word-wrap: break-word;">{{ (typeof value == 'string') ? value : senitizeFileName }}</p>
        <div :class="[...btnClass]" class="rounded text-white p-2 inline-block bg-victoria pointer" @click="$refs.file.click()">
          Browse
        </div>
        <input class="hidden" type="file" ref="file" @change="getFileContent">
    </div>
    <b class="text-danger" id="scrollTop" v-if="message">{{ message }}</b>
</div>
</template>

<script>
import { putObject } from "@/store/upload";
const AWS = require('aws-sdk');
const spacesBaseUrl = new AWS.Endpoint(process.env.VUE_APP_DIGITALOCEAN_SPACES_ENDPOINT);
const urlMarge = spacesBaseUrl.protocol+'//'+process.env.VUE_APP_DIGITALOCEAN_SPACES_BUCKET+'.'+spacesBaseUrl.host
const baseUrl =urlMarge+'/'+process.env.VUE_APP_DIGITALOCEAN_SPACES_FOLDER+'/'
export default {
  props: {
    btnClass: {
      type: Array,
      default:()=> ['text-uppercase'],
    },  
    parentClasses: {
      type: Array,
      default:()=> ['p-1'],
    },
    label: {
      type: String,
      default: () => null
    },
    italic: {
      type: Boolean,
      default: () => false
    },
    opacityClass: {
      type: String,
    },
    labelClass: {
      type: String
    },
    requiredLabel: {
      type: Boolean,
      default: () => false
    },
    message: {
      type: String,
      default: () => null
    },
    value: {
      
    },
    path: {
      type: String,
      default: () => null
    },
    isUpload: {
      type: Boolean,
      default:false,
    },
    isUseSlashImages: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      fileName: null,
      file: new FormData,
    }
  },
  computed: {
    senitizeFileName() {
      if(this.fileName && this.fileName.length > 20) {
        return this.fileName.substring(0, 20);
      }

      return this.fileName
    }
  },
  methods: {
    getFileContent(e) {
      if(!this.isUpload) {
        this.fileName = e.target.files[0].name;
        
        this.$emit('input', e.target.files[0]);
        this.file = e.target.files[0];
        
        putObject(e.target.files[0],this.path,'public-read');
      } else {
        this.updateComponentImage(e);
      }
    },
    updateComponentImage(e){
        var imageData = e.target.files[0];
        // this.path = this.path+'/images';
        putObject(imageData, this.path, "public-read");
        this.Img_path = this.isUseSlashImages ? this.path+'/images/' : this.path + '/';
        const paths = this.Img_path+imageData.name ;
        this.$emit('input',baseUrl+paths, imageData);
    },
  }
}
</script>

<style>

</style>