<template>
  <div class="p-6" style="width: 950px">
    <DialogTitle label="Create New Notice" align="center" />
    <form @submit.prevent="onSubmit">
      <div>
        <label class="text-uppercase font-bold text-xl mb-2 block mt-6">
          Notice type
        </label>
        <RadioButtonComponent
            active-class="font-bold"
            label-class="text-lg"
            :items="[{id:1, label: 'Pop-up'}, {id:2, label: 'Bar'}, {id:3, label: 'Email'}, {id:4, label: 'Message'}]"
          />
      </div>
      <div class="md-layout md-gutter my-4">
        <div class="md-layout-item md-size-55">
          <InputFieldComponent
            italic label="Notice Title"
            v-model.trim="$v.form.title.$model"
            :message="!$v.form.title.required && $v.form.title.$dirty ? 'Field is required' : null"
          />
        </div>
      </div>
      
      <div class="md-layout md-gutter my-4">
        <div class="md-layout-item md-size-30">
          <SelectComponent
            italic label="Select Branch"
            class="w-full"
            v-model.trim="$v.form.branchId.$model"
            :message="!$v.form.branchId.required && $v.form.branchId.$dirty ? 'Field is required' : null"
          />
        </div>
        <div class="md-layout-item md-size-30">
          <SelectComponent
            italic
            label="Select Page"
            class="w-full"
            v-model.trim="$v.form.pageId.$model"
            :message="!$v.form.pageId.required && $v.form.pageId.$dirty ? 'Field is required' : null"
          />
        </div>
        <div class="md-layout-item md-size-30">
          <SelectComponent
            italic
            label="Choose Grpoup"
            class="w-full"
            v-model.trim="$v.form.groupId.$model"
            :message="!$v.form.groupId.required && $v.form.groupId.$dirty ? 'Field is required' : null"
          />
        </div>

      </div>

      <div class="md-layout md-gutter  my-4">
        <div class="md-layout-item md-size-40">
          <label class="text-uppercase font-bold text-base block">Notice Type</label>
          <RadioButtonComponent
            :items="[{id:1, label: 'One Time'}, {id:2, label: 'Evertime'}]"
          />
        </div>
        <div class="md-layout-item md-size-50">
          <InputFieldComponent
            italic
            label="Embedded Link"
            class="w-full"
            v-model.trim="$v.form.embeddedLink.$model"
            :message="!$v.form.embeddedLink.required && $v.form.embeddedLink.$dirty ? 'Field is required' : null"
          />
        </div>
      </div>
        
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-size-40">
            <FileInputCompoment
            label="Choose Image"
            v-model.trim="$v.form.image.$model"
            :message="!$v.form.image.required && $v.form.image.$dirty ? 'Field is required' : null"
            />
        </div>
        <div class="md-layout-item md-size-30 flex align-center">
            <div class="p-3 bg-victoria inline-block m-1"></div>
            <div class="p-3 bg-green inline-block m-1"></div>
            <div class="p-3 bg-tahiti-gold inline-block m-1"></div>
            <div class="p-3 bg-alto inline-block m-1"></div>
            <div class="p-3 bg-mine-shaft inline-block m-1"></div>
            <div class="p-3 bg-danger inline-block m-1"></div>
        </div>
        <div class="md-layout-item md-size-30">
          <InputFieldComponent
            italic
            label="Button Name"
            class="w-full"
            v-model.trim="$v.form.buttonName.$model"
            :message="!$v.form.buttonName.required && $v.form.buttonName.$dirty ? 'Field is required' : null"
          />
        </div>
      </div>
      <TextAreaComponent
        label="Description"
        label-class="text-base"
        v-model.trim="$v.form.description.$model"
        :message="!$v.form.description.required && $v.form.description.$dirty ? 'Field is required' : null"
      />
      <div class="flex justify-between mt-4">
       <md-button class="text-uppercase text-victoria outline-gray-500 rounded">Preview</md-button>
        <div>
            <md-button class="text-uppercase bg-default rounded">Save as draft</md-button>
            <md-button class="text-uppercase mx-2 bg-green text-white rounded">Schedule</md-button>
            <md-button type="submit" class="text-uppercase mr-0 bg-victoria text-white rounded">Publish</md-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { InputFieldComponent, SelectComponent, RadioButtonComponent, DialogTitle, TextAreaComponent } from "@/components"
import FileInputCompoment from "@/components/atom/form/FileInputComponent";
import { required } from "vuelidate/lib/validators";
import { mapMutations } from "vuex";
export default {
  components: {
    DialogTitle,
    SelectComponent,
    TextAreaComponent,
    FileInputCompoment,
    InputFieldComponent,
    RadioButtonComponent
  },
  data() {
    return {
     form: {
       title: "",
       branchId: "",
       pageId: "",
       groupId: "",
       embeddedLink: "",
       image: "",
       buttonName: "",
       description: "",
     }
    };
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar"
    }),
    onSubmit() {
      this.$v.$touch();
      if(this.$v.$invalid) {
        return;
      }
      this.setShowSnackbar('Notice saved successfully !');
      setTimeout(() => {
       this.dialog(false);
      }, 1000);
    }
  },
  validations: {
    form: {
      title: {required},
      branchId: {required},
      pageId: {required},
      groupId: {required},
      embeddedLink: {required},
      image: {required},
      buttonName: {required},
      description: {required}
    }
  }
};
</script>

<style></style>
