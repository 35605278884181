export const importexport = [];
for (let i = 0; i < 15; i++) {

   importexport.push({
    id: i,
    name: 'Cristiano Ronaldo',
    designation: 'Student',
    course: 'Exp. Listening',
    branch: 'Dhanmondi',
    emailAddress: 'ronaldo@footbal.com',
    contact: '+880171229558',
    enlistedBy: 'Kazi Shahin'
   })
}

export const campaign = [];
for (let i = 0; i < 15; i++) {

    campaign.push({
        id: i,
        title: 'New admission',
        shortDescription: 'New admission for summer 2019',
        reason: 'Registration',
        branch: 'Dhanmondi',
        createdBy: 'Kazi Shahin',
        status: 'Active',
        views: '12,258',
        duration: '7 Days'
   })
}

export const automation = [];
for (let i = 0; i < 15; i++) {
    automation.push({
        id: i,
        campaignName: 'Reminder for Due Payment',
        isSend: true,
        phase1: 'Send An Email',
        phase2: 'Send An Email',
        phase3: 'Direct Call',
        status: 'Active'
   })
}

export const form = [];
for (let i = 0; i < 15; i++) {
    form.push({
        id: i,
        formName: 'Admission Form',
        createdFor: 'To Admin New Student',
        createdBy: 'Kazi Shahin',
        createdAt: '12 Jun, 2019',
        availability: 'Dhanmondi',
        status: 'Active'
   })
}

export const deal = [];
for (let i = 0; i < 15; i++) {
    deal.push({
        id: i,
        dealTitle: '13% Discount',
        shortDescription: 'Student admitted ',
        addedBy: 'Kazi Shahin',
        createdAt: '12 Jun, 2019',
        branch: 'Dhanmondi',
        duration: '10 Days',
        status: 'Active'
   })
}

export const report = [];
for (let i = 0; i < 15; i++) {
    report.push({
        id: i,
        taskId: 'T1202',
        taskName: 'Campaign for Admission',
        taskType: 'Campaign',
        createdBy: 'Kazi Shahin',
        createdAt: '12 Jun, 2019',
        branch: 'Dhanmondi',
        status: 'Active'
   })
}

